<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        Daftar {{capitalizeFirstLetter(type)}}
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h2 class="lead"><b>{{ ujian.nama_paket }}</b></h2>
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right">
                    		<router-link :to='tipe_ujian == "ujian" ? "/ujian-mapel-type" : "/soal-mapel"' class="btn btn-sm btn-secondary">
                            <i class="fas fa-arrow-left"></i> Ke Halaman Pilih {{capitalizeFirstLetter(type)}}
                    		</router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
			<table class="table table-hover" ref="tblujian">
			<thead>
				<tr>
				<th>{{type.toUpperCase()}}</th>
                <th>DURASI</th>
                <th>JUMLAH SOAL</th>
                <th>PENGERJAAN</th>
                <th>TINDAKAN</th>
				</tr>
			</thead>
			<tbody @click="handleClick"></tbody>
			</table>
        </div>
    </section>
</template>
<script>
	import { createTable, authFetch } from "@/libs/hxcore";
    export default {
    name: 'UjianMapelList',
    data: () => ({
      ujian: {},
	  roles: "",
	  tipe_ujian: '',
	  type: '',
	  jenis: ''
    }),
    created: function () {
        let id = this.$route.params.id;
		this.tipe_ujian = this.$route.params.type;
		this.jenis = this.$route.params.jenis;
        if (this.tipe_ujian == 'latihan') {
            this.loadMapel(id);
			this.type = this.$route.params.type;
        } else {
            this.loadPaket(id);
			this.type = 'try out';
        }

		this.roles = this.$route.meta.roles;
    },
    methods: {
        loadMapel(id){
			authFetch("/siswa/ujian/latihan_list/" + id).then((res) => {
				res.json().then((json) => {
				this.ujian = json;
				});
			});
        },
        loadPaket(id){
			authFetch("/siswa/ujian/list/" + id).then((res) => {
				res.json().then((json) => {
				this.ujian = json;
				});
			});
        },
        capitalizeFirstLetter(string) {
		  return string.charAt(0).toUpperCase() + string.slice(1);
		},
		handleClick(e) {
			if (e.target.matches(".link-role")) {
				this.$router.push({ path: "/permit/" + e.target.dataset.id });
				return false;
			}

			if (e.target.matches(".btn-info")) {
				if (e.target.dataset.action == "view") {
					this.$router.push("/ujian-start/" + e.target.dataset.id);
				}

				if (e.target.dataset.action == "view-psikotes") {
					this.$router.push("/ujian-start/" + e.target.dataset.id);
				}
			}

			if (e.target.matches(".btn-success")) {
				if (e.target.dataset.action == "view") {
					this.$router.push("/ujian-finish/" + e.target.dataset.id)
				}

				if (e.target.dataset.action == "rekap-kecermatan") {
					this.$router.push({path: '/rekap-hasil-kecermatan', query: {ujian_code: e.target.dataset.id}})
				}
			}

			if (e.target.matches(".btn-primary")) {
				if (e.target.dataset.action == "rank") {
					// ujian/ranking/mapel_id/code
					this.$router.push("/ujian/ranking/"+ e.target.dataset.mapelid +"/" + e.target.dataset.id);
				}
			}
		},
    },
    mounted(){
	const e = this.$refs;
	let self = this;

	this.table = createTable(e.tblujian, {
		title: "",
		language: {
			zeroRecords: "Tidak ada ujian."
		},
        scrollX: true,
		selectedRowClass: '',
		roles: ["read"],
		ajax: "/siswa/ujian/ujian_list",
		searching: true,
		paramData: function(d) {
			d.paket_id = self.$route.params.id;
			d.type = self.$route.params.type;
			d.jenis = self.$route.params.jenis;
		},
		columnDefs: [{
		    "defaultContent": "-",
		    "targets": "_all"
		}],
		columns: [
			{ data: "ujian_title" },
			{ data: "durasi",
			render: function (data, type, row, meta) {
				return `<h5>${!data ? '60 Menit' : data + ' Menit'}</h5>`;
			}, },
			{ data: "total_soal",
			render: function (data, type, row, meta) {
				return `<h5>${data} Soal</h5>`;
			}, },
			{ data: "total_ujian",
			render: function (data, type, row, meta) {
				var total = data && data != null && data != 'null' ? data : 0
				if (self.$route.params.type == 'latihan') {
					return `<h6>${total}x dari 3x percobaan</h6>`;
				} else if (row.mapel_id == 34 && row.is_psikotes == 1) {
					return `<h6>${total}x dari 1x pengerjaan</h6>`;
				} else {
					return `<h6>${total}x dari 3x pengerjaan</h6>`;
				}
			}, },
			{ data: "action",
			sortable: false,
			render: function (data, type, row, meta) {
				if (self.$route.params.type == 'ujian') {
					if (row.mapel_id == 34 && row.is_psikotes == 1) {
						if (row.total_ujian >= 1) {
						return `
						<button style="margin-bottom:8px" type="button" class="btn btn-sm btn-success" data-action="rekap-kecermatan" data-id="` + row.ujian_code + `"> Rekap Hasil</button>`;
						}
					} else {
						if (row.total_ujian >= 3) {
						return `
						<button style="margin-bottom:8px" type="button" class="btn btn-sm btn-success" data-action="view" data-id="` + row.ujian_code + `"> Pembahasan </button>
						<button style="margin-bottom:8px" type="button" class="btn btn-sm btn-primary" data-action="rank" data-id="` + row.ujian_code + `" data-mapelid="` + row.mapel_id + `">Ranking List</button>`;
						}
						
						if (row.total_ujian > 0) {
						return `
						<button style="margin-bottom:8px" type="button" class="btn btn-sm btn-info" data-action="view" data-id="` + row.ujian_code + `"> Mulai</button>
						<button style="margin-bottom:8px" type="button" class="btn btn-sm btn-primary" data-action="rank" data-id="` + row.ujian_code + `" data-mapelid="` + row.mapel_id + `">Ranking List</button>`;
						}
					} 
				}

				if (self.$route.params.type == 'latihan') {
					if (row.mapel_id == 34 && row.is_psikotes == 1) { 
						if (row.total_ujian >= 1) {
						return `<button style="margin-bottom:8px" type="button" class="btn btn-sm btn-success" data-action="rekap-kecermatan" data-id="` + row.ujian_code + `"> Rekap Hasil</button>`;
						}
					} else {
						if (row.total_ujian >= 3) {
						return `<button type="button" class="btn btn-sm btn-success" data-action="view" data-id="` + row.ujian_code + `"><i class="fas fa-eye"></i> Pembahasan </button>`;
						}
						
						if (row.total_ujian > 0) {
						return `<div class="btn-group"><button type="button" class="btn btn-sm btn-info" data-action="view" data-id="`+row.ujian_code+`"><i class="fas fa-edit"></i> Mulai iii</button></div>`;
						}
					}
				}

				if (row.total_ujian < 1) {
                    if (row.total_soal < 1) {
						if (row.mapel_id == 34 && row.is_psikotes == 1) {
							return `<div class="btn-group"><button type="button" class="btn btn-sm btn-info" data-action="view-psikotes" data-id="`+row.ujian_code+`"><i class="fas fa-edit"></i> Mulai</button></div>`;
						} else {
							return 'Belum Ada Soal.';
						}
                    } else {
                        return `<div class="btn-group"><button type="button" class="btn btn-sm btn-info" data-action="view" data-id="`+row.ujian_code+`"><i class="fas fa-edit"></i> Mulai</button></div>`;
                    }
				}
			}, },
		],
		filter: false
		// filterBy: [0],
	});
    },
}
</script>
<style type="text/css" scoped>
</style>